<template>
  <div>
      <section class="page_main">
        <div class="container">
          <h1>{{ $t("pages.about") }}</h1>
          <div class="subtitle">{{ $t("pageAbout.title_1") }}</div>
          <p class="flag_text">{{ $t("pageAbout.text_1") }}</p>
        </div>
      </section>
      <section class="section_text section_about even">
        <div class="container">
          <h2 class="title">{{ $t("pageAbout.title_2") }}</h2>
          <p>{{ $t("pageAbout.text_2") }}</p>
        </div>
      </section>
      <section class="section_text section_about">
        <div class="container">
          <p>{{ $t("pageAbout.text_3") }}</p>
          <ul class="about_process">
            <li v-for="number in 5" :key="number">
              <span>
                <img :src="require(`@/assets/images/about/${number.toString().padStart(2, '0')}.svg`)" alt="">
              </span>
              {{ $t("about_process.text_"+number) }}
            </li>
          </ul>
          <h2 class="title">{{ $t("pageAbout.text_4") }}</h2>
          <p>{{ $t("pageAbout.text_5") }}</p>
        </div>
      </section>
      <section class="section_text section_about">
        <div class="container">
          <h2 class="title">{{ $t("pageAbout.text_6") }}</h2>
          <p>{{ $t("pageAbout.text_7") }}</p>
        </div>
      </section>
      <section class="line_girl"></section>
      <section class="section_text section_about">
        <div class="container">
          <h2 class="title">{{ $t("pageAbout.text_8") }}</h2>
          <p>{{ $t("pageAbout.text_9") }}</p>
          <ul class="about_caliy">
            <li v-for="number in 5" :key="number">
              <span>
                <img :src="require(`@/assets/images/about/${number.toString().padStart(2, '0')}.svg`)" alt="">
              </span>
              {{ $t("about_caliy.text_"+number) }}
            </li>
          </ul>
        </div>
      </section>
  </div>
</template>

<script>
export default {
};
</script>