<template>
  <section class="hero">
      <swiper
      :slides-per-view="1"
      :loop="true"
      :autoplay="{
        delay: 2500
      }"
      :speed="10"
      :modules="modules"
    >
      <swiper-slide>
          <div class="hero__image img1">
          <img src="@/assets/images/slide_1.jpg" alt=""></div>
          <div class="hero__content">
            <h1 class="hero__title">{{ $t("hero.title_1") }}</h1>
            <p>{{ $t("hero.text_1") }}</p>
          </div>
      </swiper-slide>
      <swiper-slide>
          <div class="hero__image img2">
          <img src="@/assets/images/slide_2.jpg" alt=""></div>
          <div class="hero__content">
            <h1 class="hero__title">{{ $t("hero.title_2") }}</h1>
            <p>{{ $t("hero.text_2") }}</p>
          </div>
      </swiper-slide>
      <swiper-slide>
          <div class="hero__image img3">
          <img src="@/assets/images/slide_3.jpg" alt=""></div>
          <div class="hero__content">
            <h1 class="hero__title">{{ $t("hero.title_3") }}</h1>
            <p>{{ $t("hero.text_3") }}</p>
          </div></swiper-slide>
      <swiper-slide>
          <div class="hero__image img4">
          <img src="@/assets/images/slide_4.jpg" alt=""></div>
          <div class="hero__content">
            <h1 class="hero__title">{{ $t("hero.title_4") }}</h1>
            <p>{{ $t("hero.text_4") }}</p>
          </div>
      </swiper-slide>
    </swiper>
  </section>
  </template>
  <script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
  
    import 'swiper/css';

    export default {
      components: {
        Swiper,
        SwiperSlide,
      },
      setup() {
        return {
          modules: [EffectFade, Navigation, Pagination, Autoplay],
        };
      },
    };
  </script>