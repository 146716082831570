<template>
  <div>
      <section class="page_main">
        <div class="container">
          <h1>{{ $t("pages.contacts") }}</h1>
          <div class="page_contacts">
            <address>{{ $t("message.address") }}<br>{{ $t("message.address_2") }}</address>
            <a class="link_phone" href="tel:14020">Hot line 14 020</a>


            <form @submit.prevent="submitForm">
      <label for="name">Имя:</label>
      <input v-model="formData.name" type="text" id="name" required />

      <label for="email">Email:</label>
      <input v-model="formData.email" type="email" id="email" required />

      <label for="message">Сообщение:</label>
      <textarea v-model="formData.message" id="message" required></textarea>

      <input type="text" style="display: none;" v-model="hiddenField" />
      <button type="submit" class="btn">Отправить</button>
    </form>

    <div v-if="status === 'success'">Спасибо за ваше сообщение!</div>
    <div v-if="status === 'error'">Произошла ошибка при отправке сообщения. Попробуйте еще раз позже.</div>          
          </div>
          
        </div>
      </section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d670.666035577554!2d27.921876829262903!3d47.74917090042832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDQ0JzU3LjAiTiAyN8KwNTUnMjAuNyJF!5e0!3m2!1sru!2sua!4v1613826729102!5m2!1sru!2sua"
        width="100%" height="575px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>      
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      hiddenField: '',
      formData: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapState(['status']),
  },
  methods: {
    ...mapActions(['submitForm']),
    async handleSubmit() {
    if (this.hiddenField) {
      return;
    }
      await this.submitForm(this.formData);
    },
  },
};
</script>