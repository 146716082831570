import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    status: '',
    locale: localStorage.getItem('locale') || 'md', // Проверяем localStorage при инициализации
  },
  mutations: {
    setStatus(state, status) {
      state.status = status;
    },
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem('locale', locale); // Сохраняем язык в localStorage
    },
  },
  actions: {
    changeLocale({ commit }, locale) {
      commit('setLocale', locale);
    },
    async submitForm({ commit }, formData) {
      try {
        const response = await axios.post('/mail/mail.php', formData);
        if (response.data.status === 'success') {
          commit('setStatus', 'success');
        } else {
          commit('setStatus', 'error');
        }
      } catch (error) {
        console.error('Ошибка при отправке формы:', error);
        commit('setStatus', 'error');
      }
    },
  },
});