<template>
  <div id="app">
    <header class="header" :class="{ other_pages: $route.name !== 'HomePage', scrolled: scrolled, open: isOpen }">
      <div class="container">
        <div class="header_logo">
          <router-link to="/" @click="closeMenu">
            <img v-if="$route.name !== 'HomePage' || scrolled || isOpen" src="@/assets/images/logo_blue.svg" alt="Aqua Iodika" />
            <img v-else src="@/assets/images/logo.svg" alt="Aqua Iodika" />
          </router-link>
        </div>
        <nav>
          <ul>
            <li>
              <router-link to="/about" @click="closeMenu">{{ $t("pages.about") }}</router-link>
            </li>
            <li>
              <router-link to="/actions" @click="closeMenu">{{ $t("pages.actions") }}</router-link>
            </li>
            <li>
              <router-link to="/recommendation" @click="closeMenu">{{ $t("pages.recommendation") }}</router-link>
            </li>
            <li>
              <router-link to="/contacts" @click="closeMenu">{{ $t("pages.contacts") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="lang">
          <button :class="{ active: pagelocal === 'md' }" @click="changeLanguage('md')">RO</button>
          <button :class="{ active: pagelocal === 'ru' }" @click="changeLanguage('ru')">RU</button>
        </div>
        <button class="toggle" @click="toggleMenu"><span></span></button>
        <a href="https://viatabuna.md/ru/product-category/bottled-water/" class="btn">{{ $t("message.order") }}</a>
      </div>
    </header>
    <router-view></router-view>
    <footer class="footer">
      <div class="container">
        <div class="footer__left">
          <div class="footer__title">SRL BEERMASTER BREWERY</div>
          <address class="footer__address">{{ $t("message.address") }}<br>{{ $t("message.address_2") }}</address>
          <a href="mailto:VIATA_BUNA@YAHOO.COM" class="link_email">VIATA_BUNA@YAHOO.COM</a>
        </div>
        <nav>
          <ul>
            <li>
              <router-link to="/about">{{ $t("pages.about") }}</router-link>
            </li>
            <li>
              <router-link to="/actions">{{ $t("pages.actions") }}</router-link>
            </li>
            <li>
              <router-link to="/recommendation">{{ $t("pages.recommendation") }}</router-link>
            </li>
            <li>
              <router-link to="/contacts">{{ $t("pages.contacts") }}</router-link>
            </li>
          </ul>
        </nav>
        <div class="footer__phone">
          <a class="link_phone" href="tel:14020">Hot line 14 020</a>
          <a href="https://viatabuna.md/ru/product-category/bottled-water/" class="btn">{{ $t("message.order") }}</a>
        </div>
        <div class="footer__bottom">
            <div class="footer__logo">
              <router-link to="/"><img src="./assets/images/logo.svg" alt="aquaiodika"></router-link>
            </div>
            <div class="footer__copyright">
              AQUA Iodika&copy;{{ currentYear }}          
              <span>All Rights Reserved</span>
            </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import i18n from './i18n'; // Импортируйте наш объект i18n

export default {
  data() {
    return {
      scrolled: false,
      isOpen: false,
      pagelocal: 'md',
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    changeLanguage(locale) {
      i18n.global.locale = locale; // Устанавливаем выбранный язык с помощью объекта i18n
      localStorage.setItem('locale', locale); // Сохраняем язык в localStorage
      this.pagelocal = locale;
    },
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    closeMenu() {
      console.log('close');
      this.isOpen = false;
    }
  },
};
</script>
