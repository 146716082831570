// router.js
import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./pages/HomePage.vue";
import AboutPage from "./pages/AboutPage.vue";
import ActionsPage from "./pages/ActionsPage.vue";
import RecommendationPage from "./pages/RecommendationPage.vue";
import ContactPage from "./pages/ContactPage.vue";

const DEFAULT_TITLE = 'Aqua Iodika';

const routes = [
  {
    path: "/", // Этот маршрут соответствует главной странице
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/about", // Этот маршрут соответствует странице "About"
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/actions",
    name: "ActionsPage",
    component: ActionsPage,
  },
  {
    path: "/recommendation",
    name: "RecommendationPage",
    component: RecommendationPage,
  },
  {
    path: "/contacts",
    name: "ContactPage",
    component: ContactPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  next();
});

export default router;
