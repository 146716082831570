<template>
  <div>
        <section v-for="(number, index) in 9" :key="number" :class="['section_text', 'section_text_actions', index % 2 === 0 ? '' : 'even', index === 0 ? 'page_main': '']">
          <div class="container">
            <h1 v-if="index == 0">{{ $t("pages.actions") }}</h1>
            <div class="section_header">
              <img :src="require(`@/assets/images/actions/${number.toString().padStart(2, '0')}.svg`)" alt="">
              <h2>{{ $t("pageActions.title_"+number) }}</h2>
            </div>
            <p>{{ $t("pageActions.text_"+number) }}</p>
          </div>
        </section>
  </div>
</template>

<script>
export default {
};
</script>