import { createI18n } from 'vue-i18n';

const messages = {
  md: require('./locales/md.json'),
  ru: require('./locales/ru.json'),
};

const savedLocale = localStorage.getItem('locale');

const i18n = createI18n({
  locale: savedLocale || 'md',
  fallbackLocale: 'md',
  messages,
});

export default i18n;
