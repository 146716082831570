<template>
    <div>
        <main-hero></main-hero>
        <section class="advantages">
          <div class="container">
            <ul>
              <li v-for="number in 5" :key="number">
                <span>{{ $t("advantages.title_"+number) }}</span> 
                {{ $t("advantages.text_"+number) }}
                <strong>{{ $t("advantages.material_"+number) }}</strong>
              </li>
            </ul>
          </div>
        </section>
        <section class="benefits">
          <div class="container">
            <ul class="benefits__items">
              <li v-for="number in 10" :key="number">
                <span>
                  <img :src="require(`@/assets/images/benefits/${number.toString().padStart(2, '0')}.svg`)" alt="">
                </span> 
                {{ $t("benefits.title_"+number) }}
              </li>
            </ul>
          </div>
        </section>
        <section v-for="(number, index) in 4" :key="number" :class="['section_text', index % 2 === 0 ? 'even' : '', index === 0 ? 'about': '']">
          <div class="container">
            <h2>{{ $t("mainContent.title_"+number) }}</h2>
            <p>
                {{ $t("mainContent.text_"+number) }}
                <router-link to="/about" v-if="index == 0">{{ $t("message.more") }}</router-link>
            </p>
          </div>
        </section>
        <section class="line_wather"></section>
        <section class="section_products">
          <div class="container">
            <ul class="section_products__items">
              <li v-for="item in 4" :key="item">
                <div class="image">
                  <img :src="require(`@/assets/images/products/product_${item}.png`)" alt="">
                  <a href="https://viatabuna.md/ru/product-category/bottled-water/" class="btn btn-blue">{{ $t("message.order") }}</a>
                </div>
                {{ $t("products.text_"+item) }}
              </li>
            </ul>
          </div>
        </section>
    </div>
</template>

<script>
import MainHero from "@/components/MainHero.vue"; 
export default {
    components: {
        MainHero,
    },
};
</script>